// Defaults applied if not overridden (switch on expand all & dark mode): https://mui.com/material-ui/customization/default-theme/

import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  // useMediaQuery,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
// import { createLocalStorageStateHook } from "use-local-storage-state";

// https://m2.material.io/inline-tools/color/ - anything MUI not defined below is the colors & styles in this link

const baseTheme = (mode) => ({
  // styles for all components
  typography: {
    fontSize: 14,
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1500,
      xxl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          "scrollbar-color": "#6e7071 #222628",
        },
        "*::-webkit-scrollbar": {
          background: "#222628",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#6e7071",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:before": {
            display: "none",
          },
          "&:not(:last-child)": {
            borderBottom: "1px solid",
          },
          "&.Mui-expanded": {
            margin: "0 !important",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 78,
        },
        content: {
          margin: "0 !important",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
          "&:hover": {
            // backgroundColor: (theme) => theme.palette.background.dark,
            backgroundColor: "rgb(26 29 30)",
            backgroundImage: "none",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          backgroundColor: "#6a00cf", // secondary.dark
          color: "white",
          fontWeight: "bold",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "5px solid #1d1d1d",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#101214", // background.default
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#101214", // background.default
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: "#101214", // background.default
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          padding: "5px",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderColor: "rgb(81, 84, 92)",
          "&:hover:not(.Mui-disabled)": {
            borderColor: "rgb(237, 242, 247)",
          },
          "&.Mui-focused": {
            borderColor: "rgb(63, 81, 181)",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundImage: "none",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: "#101214",
        },
      },
    },
  },

  palette: {
    mode,
    ...(mode === "light"
      ? {
          // 🌞 colors for light mode
        }
      : {
          // 🌚 colors for dark mode
          primary: {
            main: "#90caf9", // icy blue
            light: "#e3f2fd",
            dark: "#42a5f5",
            superDark: "#061c40",
            lowOpacity: "rgba(144, 202, 249, 0.5)",
          },
          secondary: {
            main: "#7508d5", // purple
            light: "#8d0cff",
            dark: "#5c00c7",
          },
          tertiary: {
            main: "#3f51b5", // deep blue
            light: "#7482ca",
            dark: "#192048",
          },
          // teal: {
          //   main: "#00a68c", // teal
          //   light: "#28ab9c",
          //   dark: "#00766c",
          // },
          gold: {
            main: "#f3bd00", // used for ultimate user badge
          },
          background: {
            default: "#101214", // sitewide background color (dark grey)
            paper: "#222629", // card background color
            light: "#32383a", // lighter than cards - also for accents like borders and <hr> on the other bg colors
            dark: "#1a1d1e", // darker than cards, lighter than default
            superDark: "#0d0e10", // darkest of all
          },
          accents: {
            light: "rgb(179, 179, 179)", // lightish grey for text--MUI already uses a white+0.7transparency version of this in some spots like input field placeholders
            main: grey[700], // #616161
            dark: "#444444",
          },
          cancel: {
            main: "#f990ca", // pink
            dark: "#f73b9d",
          },
          disabled: {
            main: grey[600],
          },
        }),
  },
});

// Create a local storage hook for dark mode preference
// const useDarkModeStorage = createLocalStorageStateHook("isDarkMode", null);

const ThemeProvider = ({ children }) => {
  // Get system dark mode preference
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  // // Get stored dark mode preference
  // const [isDarkModeStored, setIsDarkModeStored] = useDarkModeStorage();

  // // Use stored dark mode with fallback to system preference
  // const isDarkMode =
  //   isDarkModeStored !== null ? isDarkModeStored : prefersDarkMode;
  const isDarkMode = true; // always dark mode for now

  // // Function to toggle dark mode
  // const toggleDarkMode = () => {
  //   setIsDarkModeStored(!isDarkMode);
  // };

  // Get MUI theme object based on the mode
  // const theme = isDarkMode ? darkTheme : lightTheme; // Replace with correct theme
  const theme = createTheme(baseTheme(isDarkMode ? "dark" : "light"));
  // theme.palette.toggle = toggleDarkMode; // Add toggle function to theme object

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

// export { ThemeProvider, useDarkModeStorage };
export { ThemeProvider };
