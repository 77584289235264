import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import { useAuth } from "./auth";

function CustomLink({ href, children, type = "router", ...props }) {
  const auth = useAuth();
  const location = useLocation();

  // Add query params to the href
  let finalHref = href || "#";
  if (!auth.user && href) {
    const queryParams = new URLSearchParams(location?.search);
    if (location.search) {
      const delimiter = href.includes("?") ? "&" : "?"; // only add ? if it's not already present
      finalHref += delimiter + queryParams?.toString(); // add query params
    }
  }

  // Render the link depending on props type
  switch (type) {
    case "mui":
      return (
        <MuiLink href={finalHref} {...props}>
          {children}
        </MuiLink>
      );
    case "router":
      return (
        <Link to={finalHref} {...props}>
          {children}
        </Link>
      );
    default:
      return (
        <a href={finalHref} {...props}>
          {children}
        </a>
      );
  }
}

export default CustomLink;
