import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import lz4 from "lz4js";
import config from "../../config";
import { logger } from "../../util/logger";
import { getStimulationPeriod } from "../../api/feagiApiBurstEngine";
import Dragger from "../Dragger";

const Webcam = ({ webcamOpen, setWebcamOpen, setError, handleErrorOpen }) => {
  // Get session ID
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const sessionId = queryParameters.get("id");
  const clusterId = queryParameters.get("cluster");
  // Establish video & websocket variables
  const videoRef = useRef(null);
  const wsRef = useRef(null);
  const [isWebsocketOpen, setIsWebSocketOpen] = useState(false);
  const [refreshRate, setRefreshRate] = useState(1000 / 30);
  // Take actions based on picture-in-picture state
  // const [pictureInPicture, setPictureInPicture] = useState(false);

  useEffect(() => {
    // Get refresh rate from FEAGI
    async function determineRefreshRate() {
      try {
        const feagiRefreshRate = await getStimulationPeriod(
          sessionId,
          clusterId
        );
        if (feagiRefreshRate * 1000 > refreshRate) {
          setRefreshRate(feagiRefreshRate * 1000);
        }
      } catch (err) {
        console.error("Error getting stimulation period:", err);
      }
    }
    // Make websocket connection
    async function connectWebsocket() {
      try {
        wsRef.current = new WebSocket(
          `wss://${sessionId}-feagi.${clusterId}.neurorobotics.studio/p9051`
        );
        console.log("Websocket connection:", wsRef.current);
        wsRef.current.onopen = () => setIsWebSocketOpen(true);
        wsRef.current.onclose = () => setIsWebSocketOpen(false);
        wsRef.current.onmessage = (event) => {
          try {
            const data = event.data;
            if (data && typeof data.newRefreshRate === "number") {
              const newRate = data.newRefreshRate * 1000;
              if (newRate !== refreshRate) setRefreshRate(newRate);
            }
          } catch (err) {
            console.error("Error parsing websocket message:", err);
          }
        };
        wsRef.current.addEventListener("error", (error) => {
          console.error("WebSocket error: ", error);
          handleClose();
          setError(
            "There was an error connecting the webcam. Please reload, or report a bug if the issue persists."
          );
          console.error("setting error open");
          handleErrorOpen();
        });
      } catch (err) {
        console.error("Error connecting websocket:", err);
        handleClose();
        setError(
          "There was an error connecting the webcam. Please reload, or report a bug if the issue persists."
        );
        handleErrorOpen();
      }
    }
    // Call the above functions
    if (sessionId) {
      determineRefreshRate();
      connectWebsocket();
    } else {
      console.log("Unable to get session ID from URL");
    }
    // Close websocket on cleanup
    return () => {
      if (wsRef.current) wsRef.current.close();
    };
    // The below linter ignore is because it wants refreshRate in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    // Turn on webcam, get video data, and send to FEAGI
    if (webcamOpen && isWebsocketOpen) {
      let stream = null;
      let intervalId = null;
      const currentVideoElement = videoRef.current; // capture value for cleanup function
      const mediaDevices = navigator.mediaDevices;

      mediaDevices
        .getUserMedia({
          // video: true,
          video: {
            width: config.webcamWidth,
            height: config.webcamHeight,
          },
        })
        .then((mediaStream) => {
          stream = mediaStream;
          logger(
            "stream settings: ",
            mediaStream.getVideoTracks()[0].getSettings()
          );
          videoRef.current.srcObject = stream;
          videoRef.current.addEventListener("loadedmetadata", () => {
            videoRef.current.play();
            intervalId = setInterval(() => {
              if (videoRef.current) {
                const canvas = document.createElement("canvas");
                // canvas.width = videoRef.current.videoWidth;
                // canvas.height = videoRef.current.videoHeight;
                canvas.width = config.webcamWidth;
                canvas.height = config.webcamHeight;
                const context = canvas.getContext("2d");

                // Draw video frame onto FEAGI canvas
                context.drawImage(
                  videoRef.current,
                  0,
                  0,
                  canvas.width,
                  canvas.height
                );

                // Store canvas data in array
                const clampedArray = context.getImageData(
                  0,
                  0,
                  canvas.width,
                  canvas.height
                ).data;

                // Create new array with width, height, & alpha values removed (size reduction)
                const rgbArray = [config.webcamWidth, config.webcamHeight];
                for (let i = 0; i < clampedArray.length; i += 4) {
                  const red = clampedArray[i];
                  const green = clampedArray[i + 1];
                  const blue = clampedArray[i + 2];

                  rgbArray.push(red, green, blue);
                }

                // Convert to clamped array for compression library
                const newClampedArray = new Uint8ClampedArray(rgbArray);

                // Compress data
                var compressed = lz4.compress(newClampedArray);

                // Send data through websocket
                wsRef.current.send(compressed);
              }
            }, refreshRate);
          });
        })
        .catch((error) => {
          alert(error);
          handleClose();
        });

      // When the `webcamOpen` prop changes to false, stop the video stream and clear the interval
      return () => {
        console.log("Shutting off video & stream sending in cleanup function");
        // Stop and clear the video stream
        if (currentVideoElement) {
          console.log("Stopping video stream");
          currentVideoElement.pause();
          currentVideoElement.srcObject = null;
        }
        // Stop all tracks of the stream to turn off the camera
        if (stream) {
          console.log("Stopping stream tracks");
          stream.getTracks().forEach((track) => track.stop());
        }
        // Clear the interval
        if (intervalId) {
          console.log("Clearing sending interval");
          clearInterval(intervalId);
        }
      };
    } else {
      console.log(
        "Websocket connection not open. Websocket (should exist):",
        wsRef.current
      );
      console.log(
        `Websocket readyState (should be 1): ${wsRef.current.readyState}. webcamOpen variable (should be true): ${webcamOpen}`
      );
    }
    // The below linter ignore is because it wants handleClose in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamOpen, isWebsocketOpen, refreshRate]);

  const handleClose = () => {
    setWebcamOpen(false);
  };

  // Track picture-in-picture close event from pip window itself
  // useEffect(() => {
  //   const videoElement = videoRef.current;

  //   const onLeavePiP = () => {
  //     // Timeout to avoid split second show of "picture-in-picture" backward text
  //     setTimeout(() => setPictureInPicture(false), 200);
  //   };

  //   if (videoElement) {
  //     videoElement.addEventListener("leavepictureinpicture", onLeavePiP);
  //   }

  //   return () => {
  //     if (videoElement) {
  //       videoElement.removeEventListener("leavepictureinpicture", onLeavePiP);
  //     }
  //   };
  // }, []);

  // Handle picture-in-picture click to open/close
  // const handlePiP = async () => {
  //   if (videoRef.current) {
  //     try {
  //       if (document.pictureInPictureElement) {
  //         await document.exitPictureInPicture();
  //       } else if (
  //         document.pictureInPictureEnabled &&
  //         !videoRef.current.disablePictureInPicture
  //       ) {
  //         await videoRef.current.requestPictureInPicture();
  //         setPictureInPicture(true);
  //       }
  //     } catch (error) {
  //       console.error("Picture-in-Picture Error:", error);
  //       setPictureInPicture(false);
  //     }
  //   }
  // };

  return (
    <>
      {/* Webcam */}
      {webcamOpen && (
        <Dragger handle=".dragHandle">
          <Card
            sx={{
              position: "absolute",
              border: "1px solid black",
              minWidth: "150px",
              minHeight: "150px",
              // ...(pictureInPicture && {
              //   minHeight: "35px",
              //   height: "35px",
              // }),
            }}
          >
            <div
              className="dragHandle"
              style={{
                padding: "2px",
                paddingLeft: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#313131",
                cursor: "move",
              }}
            >
              <Typography fontWeight="500">Webcam</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {/* <Tooltip
                  title={
                    pictureInPicture
                      ? "Close Picture-in-Picture"
                      : "Open Picture-in-Picture"
                  }
                >
                  <span>
                    <IconButton
                      onClick={handlePiP}
                      onMouseDown={(e) => e.stopPropagation()}
                      sx={{ padding: "4px" }}
                    >
                      <PictureInPicture
                        color={pictureInPicture ? "primary.main" : "white"}
                      />
                    </IconButton>
                  </span>
                </Tooltip> */}
                <IconButton
                  onClick={handleClose}
                  onMouseDown={(e) => e.stopPropagation()}
                  sx={{ padding: "4px" }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </div>
            <video
              ref={videoRef}
              style={{ width: "200px", transform: "scaleX(-1)" }} // flips horizontally: transform: "scaleX(-1)"
            />
          </Card>
        </Dragger>
      )}
    </>
  );
};

export default Webcam;
