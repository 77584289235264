// - Creation Flow -
// Summary:
// startBrain -> setSessionId -> checkBrain -> setIsBrainReady -> launchStats.genomeDeploymentTime -> redirect
// Flow:
// a useEffect for initial render calls startBrain()
// startBrain() either calls createExperiment or launchExperiment, then sets sessionId
// a useEffect for sessionId calls checkBrain()
// checkBrain() keeps checking backend until status.brain_readiness is true, then does setIsBrainReady(true)
// a useEffect for isBrainReady calls deployGenome() and sets launchStats.genomeDeploymentTime
// a useEffect for launchStats redirects to /brain-visualizer when genomeDeploymentTime is set

import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { useAuth, requireAuth } from "./../util/auth";
import SectionHeader from "./SectionHeader";
import {
  getSessionFeagiStatus,
  deployGenome,
  sendLaunchStats,
} from "../api/feagiSessionManagement";
import {
  launchExperiment,
  // createExperiment,
  getExperimentInfo,
  getLaunchCriteria,
} from "../api/experimentManagement";
import robotWorkshop from "../img/robot-workshop.png";
import { logger } from "../util/logger";

// sendLaunchStats:
//   sessionId,
//   experimentCreationTime,
//   feagiInstanceLaunchTime,
//   genomeDeploymentTime,
//   brainVisLoadTime

const BrainLoading = (props) => {
  const auth = useAuth();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const param = queryParameters.get("id");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  // const [seconds, setSeconds] = useState(0);
  const [stageSeconds, setStageSeconds] = useState(0);
  const CALL_FREQUENCY_IN_SECONDS = 5;
  const [resStatus, setResStatus] = useState(null);
  const [launchStats, setLaunchStats] = useState({
    sessionId: null,
    experimentCreationTime: null,
    feagiInstanceLaunchTime: null,
    genomeDeploymentTime: null,
    brainVisLoadTime: null,
  });
  const [isBrainReady, setIsBrainReady] = useState(false);
  const [loadedGenome, setLoadedGenome] = useState(false);
  const [error, setError] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [cluster, setCluster] = useState("");
  let timeoutId = null;
  let intervalId = null;

  // Stop loading if error occurs
  useEffect(() => {
    if (error.length > 0) {
      if (timeoutId) clearInterval(intervalId);
      if (intervalId) clearTimeout(timeoutId);
      setLoading(false);
    }
  }, [error, timeoutId, intervalId]);

  useEffect(() => {
    logger("launchStats:", launchStats);
  }, [launchStats]);

  useEffect(() => {
    // Start brain loading process
    async function startBrain() {
      try {
        setError("");
        setLoading(true);

        const accessToken = auth.user.accessToken;
        if (!accessToken)
          throw new Error("Unable to retrieve required user data.");

        if (!param) throw new Error("URL is missing required experiment info.");

        // If creating new experiment
        if (param === "create") {
          // Check if user probably hit back from BV
          // const existingExperimentId = localStorage.getItem("experimentId");
          // if (existingExperimentId) {
          //   window.location.href = `/lab`;
          // }
          // setMessage("Initializing experiment");
          // // Get data from experiment creation
          // const collectedData = localStorage.getItem("collectedData");
          // if (!collectedData)
          //   throw new Error(
          //     "Unable to retrieve required selections. Please return to the creation page and try again."
          //   );
          // const {
          //   environmentId = null,
          //   embodimentId = null,
          //   genomeId = null,
          //   title = null,
          // } = JSON.parse(collectedData);
          // logger(environmentId, embodimentId, genomeId, title);
          // if (!environmentId || !embodimentId || !genomeId || !title)
          //   throw new Error(
          //     "Unable to retrieve required selections. Please return to the creation page and try again."
          //   );
          // // Create experiment
          // const res = await createExperiment(
          //   accessToken,
          //   title,
          //   environmentId,
          //   embodimentId,
          //   genomeId
          //   // params // object
          // );
          // logger("create", res);
          // // Store experiment ID for case user hits back button from BV
          // const experimentId = res.data.experiment_id;
          // localStorage.setItem("experimentId", experimentId);
          // // Launch created experiment
          // const launchRes = await launchExperiment(accessToken, experimentId);
          // if (!launchRes.data) {
          //   throw new Error("Did not receive data from server");
          // }
          // // Set cluster for URL
          // setCluster(launchRes.data.feagi_cluster);
          // // Trigger next step by setting session ID
          // const resSessionId = launchRes.data.session_id;
          // if (!resSessionId) {
          //   throw new Error("Did not receive session ID from server");
          // }
          // setSessionId(resSessionId);
        } else {
          // If starting existing experiment
          setMessage("Starting experiment");
          // Redirect to lab if this experiment is already running
          const experimentRes = await getExperimentInfo(param, accessToken); // if param is not "create", it's experimentId
          const experimentState = experimentRes.data?.state;
          if (experimentState === "running") {
            // ?id=user-mwkqdcwwovunvuxgipfh&cluster=feagi-k8s-staging
            const sessionID = experimentRes.data?.session_id;
            const feagiCluster = experimentRes.data?.feagi_cluster;
            if (!sessionID || !feagiCluster) {
              window.location.href = "/lab";
            } else {
              window.location.href = `/brain-visualizer?id=${sessionID}&cluster=${feagiCluster}`;
            }
          }
          // Show error if server overloaded or user at max running count
          const systemCriteria = await getLaunchCriteria(accessToken);
          const { system_overload, max_concurrent_experiments_reached } =
            systemCriteria.data;
          if (system_overload) {
            setError(
              "The server is currently at capacity. Please try again later."
            );
            setLoading(false);
            return;
          } else if (
            experimentState !== "initializing" &&
            max_concurrent_experiments_reached
          ) {
            setError(
              "You already have a different experiment running. Please return to the lab if you wish to stop it and start this one."
            );
            setLoading(false);
            return;
          }

          // Call launch experiment
          const res = await launchExperiment(accessToken, param);
          logger("start", res);
          setCluster(res.data.feagi_cluster);
          const resSessionId = res.data.session_id;
          if (!resSessionId) {
            throw new Error("Did not receive session ID from composer");
          }
          setSessionId(resSessionId);
        }
      } catch (error) {
        console.error(error);
        setError(
          "Error starting experiment. Please return to the lab and try again."
        );
        setLoading(false);
      }
    }

    startBrain();
  }, [auth.user.accessToken, param]);

  // Check brain readiness until status.brain_readiness is true
  async function checkBrain() {
    setMessage("Preparing brain");

    intervalId = setInterval(async () => {
      try {
        const feagiStatus = await getSessionFeagiStatus(
          auth.user.accessToken,
          sessionId
        );
        logger("feagiStatus", feagiStatus);
        if (feagiStatus.data.brain_readiness) {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
          setIsBrainReady(true);
        }
        const path = window.location?.pathname?.split("/").pop();
        if (path !== "brain-loading") {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
          setLoading(false);
        }
        setResStatus(feagiStatus.data);
      } catch (error) {
        console.error(error);
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        setError(
          "Error launching experiment. Please return to the lab & try again."
        );
        setLoading(false);
      }
    }, CALL_FREQUENCY_IN_SECONDS * 1000);

    timeoutId = setTimeout(() => {
      setError(
        "Timed out after 5 minutes. Please return to the lab & try again."
      );
      clearInterval(intervalId);
      setLoading(false);
    }, 5 * 60 * 1000); // x minutes in milliseconds
  }

  // Deploy genome when burst engine is ready
  useEffect(() => {
    const deploy = async () => {
      try {
        const accessToken = auth.user.accessToken;
        await deployGenome(accessToken, sessionId);
        setLaunchStats((prevLaunchStats) => ({
          ...prevLaunchStats,
          genomeDeploymentTime: stageSeconds,
        }));
      } catch (error) {
        console.error(error);
        setLoadedGenome(false);
      }
    };

    if (resStatus && resStatus.burst_engine && !loadedGenome) {
      setLaunchStats((prevLaunchStats) => ({
        ...prevLaunchStats,
        feagiInstanceLaunchTime: stageSeconds,
      }));
      setMessage("Deploying genome");
      setLoadedGenome(true);
      deploy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resStatus, isBrainReady]);

  // Send stats & redirect to brain visualizer when genome time is set & brain is ready
  useEffect(() => {
    const completeLaunch = async () => {
      try {
        const accessToken = auth.user?.accessToken;
        if (!accessToken) throw new Error("Missing access token.");
        try {
          await sendLaunchStats({ accessToken, ...launchStats });
        } catch (err) {
          console.error("Error sending stats:", err);
        }
        setMessage("Brain ready! Redirecting");
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        localStorage.removeItem("collectedData");
        localStorage.removeItem("activeStep");
        localStorage.removeItem("title");
        window.location.href = `/brain-visualizer?id=${sessionId}&cluster=${cluster}`;
        // history.push(`/brain-visualizer?id=${sessionId}`);
        // router.push(`/brain-visualizer?id=${sessionId}`);
      } catch (error) {
        console.error(error);
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        setError(
          "Error completing launch. Please return to the lab and try again."
        );
      } finally {
        setLoading(false);
      }
    };

    if (
      isBrainReady &&
      launchStats.sessionId &&
      launchStats.genomeDeploymentTime
    ) {
      completeLaunch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchStats, isBrainReady]);

  // Update launch stage shown to user after session ID is set
  useEffect(() => {
    if (sessionId) {
      setLaunchStats((prevLaunchStats) => ({
        ...prevLaunchStats,
        experimentCreationTime: stageSeconds,
        sessionId: sessionId,
      }));
      checkBrain();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        (isBrainReady && launchStats.genomeDeploymentTime) ||
        error.length > 0
      ) {
        clearInterval(interval); // Stop the counter if brain is ready and genome is deployed, or if error occurred
      } else {
        // setSeconds((seconds) => seconds + 1);
      }
    }, 1000);
    return () => clearInterval(interval); // Cleanup
  }, [isBrainReady, launchStats.genomeDeploymentTime, error.length]);

  useEffect(() => {
    setStageSeconds(0);
    const interval = setInterval(() => {
      setStageSeconds((stageSeconds) => stageSeconds + 1);
      // isBrainReady && launchStats.genomeDeploymentTime && clearInterval(interval);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        paddingTop: "24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          marginTop: "20px",
          padding: { xs: 2, md: "16px" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          borderRadius: "5px",
          backgroundColor: "background.paper",
        }}
      >
        <Box
          sx={{
            width: "100%",
            my: { xs: 2, md: "0" },
            flex: { xs: undefined, md: 1 },
            order: { xs: 2, md: 1 },
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <Link to="/lab" style={{ textDecoration: "none" }}>
            <Button
              variant="outlined"
              size="medium"
              sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
            >
              To The Lab
            </Button>
          </Link>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            flex: { xs: undefined, md: 2 },
            order: { xs: 1, md: 2 },
            width: { xs: "100%", md: "auto" },
          }}
        >
          <SectionHeader
            title="Experiment Launch"
            subtitle=""
            size={4}
            textAlign="center"
          />
        </Box>
        {/* Placeholder */}
        <Box
          sx={{
            flex: { xs: undefined, md: 1 },
            order: 3,
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          sx={{
            mt: 4,
            fontSize: "1.5rem",
            fontWeight: "500",
            color: "accents.light",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&::after": {
              content: '""',
              display: "inline-block",
              width: "1em", // Reserve space for the ellipsis
              textAlign: "left",
              verticalAlign: "middle",
              animation: loading
                ? "ellipsis 1.2s steps(3, end) infinite"
                : "none",
            },
            "@keyframes ellipsis": {
              "0%": { content: '"."' },
              "33%": { content: '".."' },
              "66%": { content: '"..."' },
              "100%": { content: '"."' },
            },
          }}
        >
          {error ? "Failure to launch." : message}
        </Typography>
        {/* <Typography>Stage Time: {stageSeconds} seconds </Typography> */}
        {/* <Typography style={{ marginTop: "30px" }}>
          Experiment Startup Time:{" "}
          {launchStats.experimentCreationTime || "Pending..."}
        </Typography>
        <Typography>
          FEAGI Instance Launch Time:{" "}
          {launchStats.feagiInstanceLaunchTime || "Pending..."}
        </Typography>
        <Typography>
          Genome Deployment Time:{" "}
          {launchStats.genomeDeploymentTime || "Pending..."}
        </Typography>
        <Typography>Total Time: {seconds} seconds</Typography> */}
        {error && (
          <Box mx="auto" my={2} maxWidth="100%">
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        {loading ? <CircularProgress sx={{ my: 2 }} /> : <Box height="30px" />}
      </Box>
      <Box sx={{ width: "100%" }}>
        <img
          src={robotWorkshop}
          alt="robots building things"
          style={{
            width: "100%",
            height: "100%",
            minHeight: "30vh",
            objectFit: "cover",
          }}
        />
      </Box>
    </Container>
  );
};

export default requireAuth(BrainLoading);
