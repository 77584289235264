import { useLocation } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
// import { useDarkMode } from "./../util/theme";
import Section from "./Section";
import SocialLinks from "./SocialLinks";
import AddToAny from "../util/AddToAny";
import CustomLink from "../util/CustomLink";

function Footer(props) {
  const location = useLocation();
  const logo = props.logo;
  // const darkMode = useDarkMode();
  // props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  // Don't render anything for certain page(s)
  if (
    location?.pathname === "/instance" ||
    location?.pathname.startsWith("/brain-visualizer") ||
    location?.pathname === "/brain-loading" ||
    location?.pathname.startsWith("/book")
  ) {
    return null;
  }

  // Colors to indicate environment
  const backgroundColor =
    process.env.REACT_APP_NODE_ENV === "staging"
      ? "rgb(27 73 74)"
      : "background.default";

  return (
    <Section
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      sx={{
        zIndex: 10,
        mt: props.sticky ? "auto" : undefined,
        py: "20px",
        px: { xs: 0, md: "10px" },
        backgroundColor: backgroundColor || "background.default",
      }}
    >
      <Container
        sx={{
          "@media (min-width: 1200px)": {
            maxWidth: "none", // This specifically overrides the max-width at 1200px and above
          },
          mt: { xs: 2, lg: 0 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          {/* left */}
          <Box
            sx={{
              width: "100%",
              flex: { xs: "none", md: "50%" },
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              alignItems: "center",
              gap: 2,
            }}
          >
            <a
              href="https://www.neuraville.com"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "grey" }}
            >
              {logo ? (
                <img
                  src={logo}
                  alt="Logo"
                  style={{ display: "block", height: 32 }}
                />
              ) : (
                <Typography
                  sx={{
                    minWidth: "25px",
                    borderRadius: "100%",
                    border: "1px solid grey",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  N
                </Typography>
              )}
            </a>
            <Box
              display="flex"
              sx={{
                opacity: 0.6,
                fontSize: "0.875rem",
                "& a": {
                  color: "inherit",
                  marginLeft: "0.8rem",
                },
              }}
            >
              <Typography fontSize="0.875rem">{props.copyright}</Typography>
              <CustomLink href="/legal/terms-of-service">Terms</CustomLink>
              <CustomLink href="/legal/privacy-policy">Privacy</CustomLink>
            </Box>
          </Box>

          {/* right */}
          <Box
            sx={{
              width: "100%",
              mb: { xs: 1, md: 0 },
              flex: { xs: "none", md: "50%" },
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
              alignItems: "flex-end",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            {/* <Typography sx={{ marginRight: "20px" }}>
              <StyledLink component={Link} to="/business">
                Business
              </StyledLink>
            </Typography> */}
            <Typography sx={{ marginRight: { xs: "0", md: "20px" } }}>
              <CustomLink
                href="/contact"
                type="mui"
                style={{
                  color: "inherit",
                  lineHeight: 1,
                }}
              >
                Contact
              </CustomLink>
            </Typography>
            <SocialLinks />
            <AddToAny color="transparent" />
          </Box>
        </Box>
      </Container>
    </Section>
  );
}

export default Footer;
