import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import defaultImage from "../../img/robot-neutral.png";
import { getBehaviors } from "../../api/behaviorManagement";
import CarouselComplex from "../CarouselComplex";

const NavbarBehaviors = ({
  experiment,
  accessToken,
  error,
  setError,
  awaitAugment,
}) => {
  const [behaviorsOpen, setBehaviorsOpen] = useState(false);
  const [behaviorsGenomes, setBehaviorsGenomes] = useState([]);

  async function handleBehaviorsOpen() {
    try {
      // call API with embodiment ID
      const embodimentId = experiment.embodiment_id;
      if (!accessToken) throw new Error("Unable to retrieve access token.");
      const res = await getBehaviors(embodimentId, accessToken);
      const behaviors = res.data;
      if (!behaviors || behaviors.length === 0) {
        alert("Sorry, there are no behaviors for this embodiment.");
        return;
      }

      const items = behaviors.map((behavior) => {
        return {
          id: behavior.genome_id,
          content: (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              height="100%"
              padding="5px"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
                sx={{ flex: "2" }}
              >
                <img
                  src={behavior.image_url || defaultImage}
                  alt="robot"
                  crossorigin="anonymous"
                  style={{
                    width: "100px",
                    maxHeight: "100px",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                width="90%"
                sx={{ flex: "1.3" }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {behavior.title}
                </Typography>
                <Typography
                  style={{
                    fontSize: ".8rem",
                    maxWidth: "100%",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                  }}
                >
                  {behavior.description}
                </Typography>
              </Box>
            </Box>
          ),
        };
      });
      //   handleClose();
      setBehaviorsGenomes(items);
      setBehaviorsOpen(true);
    } catch (err) {
      console.error(err);
    }
  }

  const handleBehaviorsClose = () => {
    setBehaviorsOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={handleBehaviorsOpen}
        disabled={awaitAugment || !experiment || !accessToken}
      >
        Behaviors
      </Button>
      {/* Behaviors carousel popup */}
      <Dialog
        open={behaviorsOpen}
        onClose={handleBehaviorsClose}
        aria-labelledby="behaviors-title"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            position: "relative",
            top: "40px",
          },
        }}
        maxWidth="lg"
      >
        <DialogTitle
          id="behaviors-title"
          sx={{ display: "flex", justifyContent: "flex-end", padding: "3px" }}
        >
          <IconButton size="small" onClick={handleBehaviorsClose}>
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: "40vw" }}>
          <CarouselComplex
            items={behaviorsGenomes}
            handleBehaviorsClose={handleBehaviorsClose}
            accessToken={accessToken}
            experiment={experiment}
          />
          {/* <CarouselFromLibrary behaviorsGenomes={behaviorsGenomes} /> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NavbarBehaviors;

// const behaviors = [
//   {
//     title: "Chase",
//     image_link:
//       "https://storage.googleapis.com/embodiment_images/08959c0b-9c38-43ac-b3ac-8c7a37a2a5ba_1.b508de36c30ad6b1a3c6d421faa7ae34.jpeg.webp",
//     description: "Enables robot to detect movement and chase after them.",
//     embodiment_id: "em_412413423423",
//     behavior_id: "b_4234234234234",
//     genome_id: "g_12341231232312",
//   },
//   etc....
// ];
