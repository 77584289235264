import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ThemeProvider } from "../styles/theme";
// Top-level error catcher
import ErrorBoundary from "../components/ErrorBoundary";
// Utils
import { AuthProvider, useAuth } from "./../util/auth";
import useCurrentPath from "../util/useCurrentPath";
import { setBadgeUpdater } from "../util/badgeUpdater";
// Pages
import NotFoundPage from "./404";
import AuthPage from "./auth";
import OAuthCallbackHandler from "../components/auth/OAuthCallbackHandler";
import OAuthFailureHandler from "../components/auth/OAuthFailureHandler";
import BookPage from "./book";
import BrainLoading from "../components/BrainLoading";
import BrainVisualizerPage from "./brain-visualizer";
import Contact from "../components/Contact";
import EmbodimentPage from "./embodiment";
import Footer from "./../components/Footer";
import LabPage from "./lab";
import LegalPage from "./legal";
import NewExperimentPage from "./new-experiment";
import RegionUnsupportedPage from "./region-unsupported";
import SettingsPage from "./settings";
import ServerDown from "../components/ServerDown";
// Components
import NavbarWrapper from "../components/navbar/NavbarWrapper";
// Media
import logo from "../img/neuraville-logo.png";
import UserProfilePage from "./user-profile";
import BadgeHandler from "../components/BadgeHandler";

const RedirectIfUser = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const pathSegments = window.location.pathname.split("/");
  const baseRoute = pathSegments[1];
  const authType = pathSegments[2];
  const validAuthTypes = ["signin", "signup", "forgotpass"];
  const hasSignedIn = localStorage.getItem("hasSignedIn");

  useEffect(() => {
    if (auth?.user?.accessToken) {
      // #business
      // if (auth.user?.roles?.includes("business")) {
      //   history.replace("/pricing");
      // } else {
      navigate("/lab");
      // }
    } else if (hasSignedIn && baseRoute === "") {
      navigate("/auth/signin");
    }
    setIsLoading(false);
  }, [auth, auth?.user, navigate, hasSignedIn, baseRoute]);

  if (isLoading) {
    return null;
  }

  if (baseRoute === "auth" && !validAuthTypes.includes(authType)) {
    return <NotFoundPage />;
  }

  // return <IndexPage />;
  return <AuthPage />;
};

function App(props) {
  // Embodiments
  const [webcamOpen, setWebcamOpen] = useState(false);
  const [freeNoveOpen, setFreeNoveOpen] = useState(false);
  const [mycobotOpen, setMycobotOpen] = useState(false);
  const [cozmoOpen, setCozmoOpen] = useState(false);
  // Paths
  const currentPath = useCurrentPath();
  const [isAuthPage, setIsAuthPage] = useState(
    currentPath === "/" ||
      currentPath === "/business" ||
      currentPath.startsWith("/auth")
  );
  // Badges
  const [badges, setBadges] = useState(null);

  // Give global access to badge setter
  useEffect(() => {
    setBadgeUpdater(setBadges);
  }, []);

  // Update isAuthPage when path changes
  useEffect(() => {
    setIsAuthPage(
      currentPath === "/" ||
        currentPath === "/business" ||
        currentPath.startsWith("/auth")
    );
  }, [currentPath]);

  // Navbar height to calculate iFrame to fill 100% combined
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef();

  useLayoutEffect(() => {
    if (navbarRef && navbarRef.current) {
      setNavbarHeight(navbarRef.current.offsetHeight);
    }
  }, [navbarRef]);

  return (
    <ErrorBoundary>
      {/* <QueryClientProvider> */}
      <ThemeProvider>
        <AuthProvider>
          <BrowserRouter>
            <Box
              sx={{
                display: "grid",
                gridTemplateRows: "auto 1fr auto", // Header, content, footer
                minHeight: "100vh",
                backgroundColor: isAuthPage ? "black" : "background.default",
              }}
            >
              <NavbarWrapper
                color="primary"
                logo={logo}
                webcamOpen={webcamOpen}
                setWebcamOpen={setWebcamOpen}
                freeNoveOpen={freeNoveOpen}
                setFreeNoveOpen={setFreeNoveOpen}
                mycobotOpen={mycobotOpen}
                setMycobotOpen={setMycobotOpen}
                cozmoOpen={cozmoOpen}
                setCozmoOpen={setCozmoOpen}
                ref={navbarRef}
              />
              <Box
                sx={{
                  // flexGrow: 1,
                  minHeight: {
                    xs: "calc(100vh - 136px - 64px)",
                    lg: "calc(100vh - 72px - 64px)",
                  },
                }}
              >
                <Routes>
                  <Route path="/" element={<RedirectIfUser />} />

                  <Route
                    path="/auth/oauth_callback/success"
                    element={<OAuthCallbackHandler />}
                  />

                  <Route
                    path="/auth/oauth_callback/failure"
                    element={<OAuthFailureHandler />}
                  />

                  <Route path="/auth/:type" element={<RedirectIfUser />} />

                  <Route path="/book/:type" element={<BookPage />} />

                  <Route path="/brain-loading" element={<BrainLoading />} />

                  <Route
                    path="/brain-visualizer"
                    element={
                      <BrainVisualizerPage
                        webcamOpen={webcamOpen}
                        setWebcamOpen={setWebcamOpen}
                        navbarHeight={navbarHeight}
                      />
                    }
                  />

                  {/* <Route path="/business" element={RedirectIfUser} /> */}

                  <Route path="/contact" element={<Contact />} />

                  <Route path="/contact/:type" element={<Contact />} />

                  <Route path="/embodiment" element={<EmbodimentPage />} />

                  <Route path="/lab" element={<LabPage />} />

                  <Route path="/legal/:section" element={<LegalPage />} />

                  <Route
                    path="/new-experiment"
                    element={<NewExperimentPage />}
                  />

                  {/* <Route path="/pricing" element={<PricingPage} /> */}

                  <Route
                    path="/region-unsupported"
                    element={<RegionUnsupportedPage />}
                  />

                  <Route path="/server-down" element={<ServerDown />} />

                  <Route path="/settings/:section" element={<SettingsPage />} />

                  <Route path="/user/:id" element={<UserProfilePage />} />

                  <Route path="/404" element={<NotFoundPage />} />

                  <Route path="*" element={<NotFoundPage />} />

                  {/* <Route
                      path="/purchase/:plan"
                      element={<PurchasePage}
                    /> */}

                  {/* <Route
                    path="/firebase-action"
                    element={<FirebaseActionPage}
                  /> */}

                  {/* <Route path="/instance" element={<instancePage} /> */}

                  {/* <Route
                    path="/webcam"
                    render={(routeProps) => (
                      <Webcam
                        {...routeProps}
                        // webcamUrl={webcamUrl}
                      />
                    )}
                  /> */}

                  {/* <Route
                      path="/business/auth/:type"
                      element={<RedirectIfUser}
                    /> */}
                </Routes>
              </Box>
              <Footer
                size=""
                bgImage=""
                bgImageOpacity={1}
                description="Creating brains for robots"
                copyright={`© ${new Date().getFullYear()} Neuraville Inc.`}
                logo={logo}
                sticky={true}
              />
              {/* Badge notification popup(s) */}
              {Array.isArray(badges) && badges.length > 0 && (
                <BadgeHandler badges={badges} setBadges={setBadges} />
              )}
            </Box>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
      {/* </QueryClientProvider> */}
    </ErrorBoundary>
    // <BrowserRouter>
    //   <Routes>
    //     <Route
    //       path="/"
    //       element={<div style={{ color: "white", fontSize: "2rem" }}>Home</div>}
    //     />
    //     <Route
    //       path="/test"
    //       element={
    //         <div style={{ color: "white", fontSize: "2rem" }}>Test Page</div>
    //       }
    //     />
    //   </Routes>
    // </BrowserRouter>
  );
}

export default App;
