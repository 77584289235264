import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";

// Get behaviors for an embodiment
export async function getBehaviors(embodimentId, accessToken) {
  try {
    // Validate args
    apiValidateArguments({ embodimentId, accessToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/behaviors/query?embodiment_id=${embodimentId}`,
      "GET",
      null,
      { Authorization: accessToken }
    );

    logger("Response in getBehaviors function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getBehaviors function:", error);
    throw error;
  }
}
