import { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AcUnit as AcUnitIcon,
  Cancel as CancelIcon,
  CheckBox as CheckBoxIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  PlayCircle as PlayCircleIcon,
  Star as StarIcon,
  StarOutline as StarOutlineIcon,
  StopCircle as StopCircleIcon,
  InfoOutlined,
} from "@mui/icons-material";
import { useAuth } from "../../util/auth";
import {
  callDeleteExperiment,
  // editExperiment,
  formatDate,
  getExperimentAge,
  callStartExperiment,
  callStopExperiment,
  toggleFave,
  viewExperiment,
} from "./utils";
import ExperimentCardMemory from "./ExperimentCardMemory";
import ReviveBrain from "../ReviveBrain";
// import StandardCard from "../StandardCard";
import { updateExperiment } from "../../api/experimentManagement";
import CustomDialog from "../CustomDialog";
import NewExperimentGenomeCard from "../new-experiment/NewExperimentGenomeCard";
import { getGenomeById } from "../../api/genomeManagement";

const ExperimentCard = ({
  experiment,
  setError,
  setMessage,
  clearAlerts,
  setLoading,
  isRunning,
  setIsRunning,
  setExperiments,
  refreshExperiments,
  navigate,
  isBrainVis, // indicates whether on BV page
  browserInfo,
  setUnsupportedModalOpen,
  dontShowUnsupportedModal,
  setPostModalAction,
}) => {
  const auth = useAuth();
  const accessToken = auth.user.accessToken;
  const roles = auth.user.roles;
  const [now, setNow] = useState(moment());
  const [showMore, setShowMore] = useState(false);
  const [isFave, setIsFave] = useState(experiment.is_fav);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [titleIsEdited, setTitleIsEdited] = useState(false);
  const [descIsEdited, setDescIsEdited] = useState(false);
  const [buttonText, setButtonText] = useState("Start");
  const [openDeleteCheck, setOpenDeleteCheck] = useState(false);
  const [stopDialogOpen, setStopDialogOpen] = useState(false);
  const [genome, setGenome] = useState(null);
  const [genomeOpen, setGenomeOpen] = useState(false);
  const [reviveOpen, setReviveOpen] = useState(false);

  // Show current running experiment(s) age in realtime
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment());
    }, 60000); // update time every minute
    return () => {
      clearInterval(interval); // cleanup on unmount
    };
  }, []);

  // Track if user edits title/description to same as where they started
  useEffect(() => {
    const titleIsEdited =
      title !== null && title !== experiment.experiment_title;

    const descIsEdited =
      description !== null && description !== experiment.experiment_description;

    setTitleIsEdited(titleIsEdited);
    setDescIsEdited(descIsEdited);
    setIsEdited(titleIsEdited || descIsEdited);
  }, [experiment, title, description]);

  // Submit title/description edits
  const handleEdit = async () => {
    try {
      clearAlerts();
      // Validate
      if (!experiment) throw new Error("Unable to get experiment data.");
      if (!titleIsEdited && !descIsEdited) {
        setError("No changes to update.");
        return;
      }
      if (titleIsEdited && title?.trim().length === 0) {
        setError("Title is required");
        return;
      }
      // Update experiment
      await updateExperiment(
        accessToken,
        experiment.experiment_id,
        titleIsEdited ? title : null,
        descIsEdited ? description : null
      );
      // Refresh experiments
      if (isBrainVis) {
        await refreshExperiments();
      } else {
        await refreshExperiments(
          accessToken,
          setExperiments,
          setError,
          setLoading
        );
      }

      setIsEditing(false);
      setTitle(null);
      setDescription(null);
      setMessage("Your experiment was successfully updated.");
    } catch (err) {
      console.error(err);
      setError(
        "Sorry, there was an error updating your experiment. Please refresh and try again soon."
      );
    }
  };

  // Cancel editing
  const handleCancelEdit = () => {
    clearAlerts();
    setTitle(experiment?.experiment_title);
    setDescription(experiment?.experiment_description);
    setIsEditing(false);
  };

  // Open revive brain state modal
  const handleReviveClick = () => {
    clearAlerts();
    setReviveOpen(true);
  };

  // Ask to confirm stop experiment
  const confirmStop = async (event) => {
    event.stopPropagation();
    clearAlerts();
    setStopDialogOpen(true);
  };

  // Close stop confirmation
  const handleStopDialogClose = () => {
    setStopDialogOpen(false);
  };

  // Stop experiment
  const handleStop = async (event) => {
    event.stopPropagation();
    try {
      clearAlerts();
      callStopExperiment(
        accessToken,
        experiment.experiment_id,
        setLoading,
        setError,
        setMessage,
        setButtonText,
        setIsRunning,
        setExperiments,
        refreshExperiments
      );
      isBrainVis && navigate(`/lab`);
    } catch (err) {}
  };

  // Open
  const handleGenomeOpen = async () => {
    try {
      setError("");
      if (!experiment?.experiment_genome_id) {
        throw new Error("Genome data unavailable.");
      }
      const res = await getGenomeById({
        accessToken,
        genomeId: experiment.experiment_genome_id,
      });
      const resGenome = res.data;
      setGenome(resGenome);
      setGenomeOpen(true);
    } catch (err) {
      console.error(err);
      setError(
        "Sorry, we were unable to retrieve the genome data. Please try again soon."
      );
    }
  };

  return (
    <>
      {/* Removed the card version cuz rendering TopLeft as a separate component made input fields lose focus on each keystroke */}
      {/* <StandardCard
        key={experiment.experiment_id}
        TopLeft={TopLeft}
        TopRight={TopRight}
        BottomLeft={BottomLeft}
        showMore={showMore}
        setShowMore={setShowMore}
        cardStyle={{
          border: experiment.state === "running" ? "2px solid white" : "none",
        }}
      /> */}
      <Card
        sx={{
          width: "100%",
          border: (theme) =>
            experiment.state === "running" ||
            experiment.state === "initializing"
              ? `1px solid ${theme.palette.primary.lowOpacity}`
              : "none",
        }}
      >
        <CardContent
          sx={{
            width: "100%",
            pt: "16px",
            pb: "16px !important",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {/* Left Column */}
          <Box
            display="flex"
            flexDirection="column"
            sx={{ width: { xs: "100%", md: "70%" } }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              // gap="5vw"
              flexDirection="column"
              alignItems="center"
              // sx={{
              //   marginBottom: "10px",
              //   [theme.breakpoints.down("sm")]: {
              //     flexDirection: "column-reverse",
              //     gap: "10px",
              //     alignItems: "flex-start",
              //   },
              //   [theme.breakpoints.up("md")]: {
              //     flexDirection: "column",
              //     gap: 0,
              //     alignItems: "center",
              //   },
              // }}
            >
              <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                overflow="hidden"
                width="100%"
              >
                {/* Title */}
                {isEditing ? (
                  <Box display="flex">
                    <TextField
                      id="title"
                      type="text"
                      label="Title"
                      name="title"
                      placeholder="Title"
                      required
                      variant="outlined"
                      inputProps={{ maxLength: 50 }}
                      value={
                        title !== null ? title : experiment.experiment_title
                      }
                      onChange={(e) => setTitle(e.target.value)}
                      sx={{ width: "100%", margin: "5px 5px 10px 0" }}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Box
                      style={{
                        display: "inline-flex", // 🌟 Make it inline, like a twinkling star!
                        gap: "5px",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          ...(!showMore
                            ? {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                              }
                            : {}),
                        }}
                      >
                        {experiment.experiment_title}
                      </Typography>
                      {/* {(experiment.state === "running" ||
                        experiment.state === "initializing") && (
                        <Tooltip
                          title={`Experiment is currently ${
                            experiment.state
                          }. Click eye icon to ${
                            experiment.state === "running"
                              ? "view"
                              : "resume launch"
                          }`}
                        >
                          <RunCircle
                            sx={{
                              verticalAlign: "middle",
                              color: "#00872f",
                            }}
                          />
                        </Tooltip>
                      )} */}
                    </Box>
                  </Box>
                )}
              </Box>
              {/* Environment */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  marginBottom: "10px",
                  ...(!showMore
                    ? {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }
                    : {}),
                }}
              >
                <Typography
                  sx={{
                    color: "text.secondary",
                    fontWeight: "bold",
                    minWidth: "140px",
                  }}
                >
                  Environment
                </Typography>
                <Typography
                  sx={{
                    ...(!showMore
                      ? {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }
                      : { whiteSpace: "normal" }),
                    hyphens: "auto",
                  }}
                >
                  {experiment.environment_title}
                </Typography>
              </Box>

              {/* Embodiment */}
              <Box
                display="flex"
                width="100%"
                flexWrap="wrap"
                sx={{
                  ...(!showMore
                    ? {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }
                    : { whiteSpace: "normal" }),
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    minWidth: "140px",
                    color: "text.secondary",
                  }}
                >
                  Embodiment
                </Typography>{" "}
                <Typography
                  style={{
                    ...(!showMore
                      ? { whiteSpace: "nowrap" }
                      : { whiteSpace: "normal" }),
                    hyphens: "auto",
                  }}
                >
                  {experiment.embodiment_title}
                </Typography>
              </Box>

              {/* Genome */}
              <Box
                display="flex"
                width="100%"
                style={{ overflow: "hidden", margin: "10px 0" }}
              >
                <Box
                  display="flex"
                  width="100%"
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      minWidth: "140px",
                      color: "text.secondary",
                    }}
                  >
                    Genome
                  </Typography>
                  {/* <Typography
                    style={{
                      ...(!showMore
                        ? { whiteSpace: "nowrap" }
                        : { whiteSpace: "normal" }),
                      hyphens: "auto",
                    }}
                  >
                    {experiment.experiment_genome_title}
                  </Typography> */}
                  <Tooltip title="Genome info">
                    <IconButton onClick={handleGenomeOpen} sx={{ ml: "-5px" }}>
                      <InfoOutlined fontSize="small" />{" "}
                      <Typography ml="4px">Details</Typography>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              {/* Genome popup */}
              <Dialog
                open={genomeOpen}
                onClose={() => setGenomeOpen(false)}
                aria-labelledby="genome-title"
                aria-describedby="genome-description"
                maxWidth="lg"
              >
                <DialogTitle id="genome-title">
                  {"Experiment Genome Info"}
                  <IconButton
                    style={{
                      position: "absolute",
                      right: 7,
                      top: 7,
                      padding: 3,
                    }}
                    onClick={() => setGenomeOpen(false)}
                    size="small"
                  >
                    <CancelIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent
                  sx={{
                    minWidth: { xs: "90vw", md: "960px" },
                    overflowY: "visible",
                  }}
                >
                  <NewExperimentGenomeCard
                    accessToken={accessToken}
                    row={genome}
                    setError={setError}
                    setSeedOpen={setGenomeOpen}
                  />
                </DialogContent>
              </Dialog>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              width="100%"
            >
              <Box display="flex" justifyContent="space-between">
                <Box flex width="100%">
                  {/* Description */}
                  {isEditing ? (
                    <Box display="flex">
                      <TextField
                        variant="outlined"
                        type="text"
                        label="Description"
                        name="description"
                        placeholder="description"
                        fullWidth={true}
                        multiline
                        maxRows="30"
                        minRows="1"
                        style={{ margin: "5px 5px 5px 0", width: "100%" }}
                        value={
                          description !== null
                            ? description
                            : experiment.experiment_description
                        }
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      // sx={{
                      //   [theme.breakpoints.down("sm")]: {
                      //     flexDirection: "column",
                      //   },
                      //   [theme.breakpoints.up("md")]: {
                      //     flexDirection: "row",
                      //   },
                      // }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          minWidth: "140px",
                          color: "text.secondary",
                        }}
                      >
                        Description{" "}
                      </Typography>
                      <Typography
                        style={showMore ? { whiteSpace: "normal" } : {}}
                      >
                        {experiment.experiment_description}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              {showMore && (
                <Box sx={{ width: "100%", marginTop: "10px" }}>
                  <Typography
                    variant="span"
                    sx={{
                      minWidth: "140px",
                      display: "inline-block",
                      color: "text.secondary",
                    }}
                  >
                    <strong>
                      {experiment.state === "running"
                        ? "Age"
                        : experiment.state === "initializing"
                        ? "Initialized"
                        : "Last Run"}
                    </strong>
                  </Typography>{" "}
                  {experiment.state === "running"
                    ? getExperimentAge(
                        experiment.experiment_last_run,
                        moment,
                        now
                      )
                    : formatDate(experiment.experiment_last_run)}
                </Box>
              )}
            </Box>
          </Box>

          {/* Right Column (buttons, icons) */}
          {/* Buttons */}
          <Box
            sx={{
              mb: "5px",
              mr: { xs: 0, md: "-27px" },
              width: { xs: "100%", md: "30%" },
              order: { xs: "-1", md: "0" },
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              alignItems: { xs: "center", md: "flex-end" },
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "flex-start",
                  md: "flex-end",
                },
                flexWrap: "wrap",
                width: "100%",
                gap: { xs: "3px", md: "17px" },
              }}
            >
              {/* Stop button for running experiment */}
              {(experiment.state === "running" ||
                experiment.state === "initializing") && (
                <>
                  <Tooltip title="Stop experiment">
                    <span>
                      <IconButton
                        onClick={(event) =>
                          isBrainVis ? confirmStop(event) : handleStop(event)
                        }
                        disabled={isEditing}
                      >
                        <StopCircleIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              )}
              {/* Start button for non-running experiment */}
              {experiment.state !== "running" &&
                experiment.state !== "initializing" && (
                  <>
                    <Tooltip
                      title={
                        buttonText === "Start" && isRunning
                          ? "An experiment is already running. Stop it to start this one"
                          : buttonText === "Start"
                          ? "Launch this experiment in browser"
                          : "Stop this experiment"
                      }
                      PopperProps={{
                        style: { fontSize: "1rem" },
                      }}
                    >
                      {buttonText === "Start" ? (
                        <span>
                          <IconButton
                            onClick={() => {
                              if (!isRunning) {
                                clearAlerts();
                                const action = () =>
                                  callStartExperiment(
                                    accessToken,
                                    experiment.experiment_id,
                                    setLoading,
                                    setError,
                                    setMessage
                                  );
                                if (
                                  !isBrainVis &&
                                  (browserInfo?.isMobile ||
                                    (browserInfo?.isUsingIntelMac &&
                                      browserInfo.browser !== "Safari")) &&
                                  !dontShowUnsupportedModal
                                ) {
                                  const dontShowNow = localStorage.getItem(
                                    "dontShowIntelMacWarning"
                                  );
                                  if (dontShowNow) {
                                    action();
                                  } else {
                                    setPostModalAction(() => action);
                                    setUnsupportedModalOpen(true);
                                  }
                                } else {
                                  action();
                                }
                              }
                            }}
                            disabled={
                              isEditing || (buttonText === "Start" && isRunning)
                            }
                          >
                            <PlayCircleIcon title="launch experiment" />
                          </IconButton>
                        </span>
                      ) : (
                        // Stop button for currently running experiment
                        <span>
                          <IconButton
                            onClick={(event) => {
                              isBrainVis
                                ? confirmStop(event)
                                : isRunning && handleStop(event);
                            }}
                            disabled={isEditing}
                          >
                            <StopCircleIcon title="stop experiment" />
                          </IconButton>
                        </span>
                      )}
                    </Tooltip>
                  </>
                )}
              {/* Title & Description edit button */}
              <Tooltip
                title={
                  isEditing ? "Confirm/cancel edits below" : "Edit details"
                }
              >
                <span>
                  <IconButton
                    onClick={() => setIsEditing(true)}
                    disabled={isEditing}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              {!isBrainVis &&
                ["pro-user", "ultimate-user"].some((role) =>
                  roles?.includes(role)
                ) && (
                  <>
                    {/* Change brain state button */}
                    <Tooltip
                      title={"Select a different brain state for launch [PRO]"}
                    >
                      <span>
                        <IconButton
                          onClick={handleReviveClick}
                          // disabled={!isPro}
                          disabled={
                            isEditing
                            // ||
                            // (!roles?.includes("pro-user") &&
                            //   !roles?.includes("ultimate-user"))
                          }
                        >
                          <AcUnitIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    {/* Change brain state modal */}
                    {reviveOpen && (
                      <ReviveBrain
                        accessToken={accessToken}
                        experiment={experiment}
                        reviveOpen={reviveOpen}
                        setReviveOpen={setReviveOpen}
                        setError={setError}
                        setMessage={setMessage}
                        clearAlerts={clearAlerts}
                      />
                    )}
                    {/* Change memory tier button */}
                    <ExperimentCardMemory isEditing={isEditing} roles={roles} />
                  </>
                )}
              {/* Favorite button */}
              <Tooltip title="Toggle favorite">
                <IconButton
                  onClick={() => {
                    clearAlerts();
                    toggleFave(
                      accessToken,
                      experiment.experiment_id,
                      setIsFave,
                      setError
                    );
                  }}
                  disabled={isEditing}
                >
                  {isFave ? (
                    <StarIcon sx={{ color: "gold", fill: "gold" }} />
                  ) : (
                    <StarOutlineIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            {/* View in BV button */}
            {(experiment.state === "running" ||
              experiment.state === "initializing") && (
              <>
                {!isBrainVis && (
                  <Tooltip title="Open running experiment in brain visualizer">
                    <span>
                      <Button
                        size="small"
                        variant="contained"
                        disabled={isEditing}
                        sx={{ minWidth: "150px" }}
                        onClick={(event) => {
                          event.stopPropagation();
                          const action = () => {
                            if (experiment.state === "running") {
                              viewExperiment(
                                experiment.experiment_id,
                                setLoading,
                                setError,
                                setMessage,
                                accessToken
                              );
                            } else {
                              callStartExperiment(
                                accessToken,
                                experiment.experiment_id,
                                setLoading,
                                setError,
                                setMessage
                              );
                            }
                          };
                          if (
                            !isBrainVis &&
                            (browserInfo?.isMobile ||
                              (browserInfo?.isUsingIntelMac &&
                                browserInfo.browser !== "Safari")) &&
                            !dontShowUnsupportedModal
                          ) {
                            const dontShowNow = localStorage.getItem(
                              "dontShowIntelMacWarning"
                            );
                            if (dontShowNow) {
                              action();
                            } else {
                              setPostModalAction(() => action);
                              setUnsupportedModalOpen(true);
                            }
                          } else {
                            clearAlerts();
                            action();
                          }
                        }}
                      >
                        Brain Visualizer
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
          </Box>

          {/* Editing buttons & show/hide more buttons */}
          <Box
            display="flex"
            justifyContent={isEditing ? "space-between" : "flex-end"}
            sx={{ width: { xs: "100%", md: "auto" }, alignSelf: "flex-end" }}
          >
            {/* Editing */}
            {isEditing && (
              <Box
                display="flex"
                gap="10px"
                sx={{
                  position: "relative",
                  left: { xs: "auto", md: "-98px" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    borderRadius: "5px",
                    backgroundColor: "background.light",
                  }}
                >
                  <Tooltip title={!isEdited ? "No changes detected" : null}>
                    <span>
                      <IconButton onClick={handleEdit} disabled={!isEdited}>
                        <CheckBoxIcon
                          color={isEdited ? "success" : "disabled.main"}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Cancel editing">
                    <IconButton onClick={handleCancelEdit}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    gap: "5px",
                    borderRadius: "5px",
                    backgroundColor: "background.light",
                  }}
                >
                  <Tooltip
                    title={
                      experiment.state === "running" ||
                      experiment.state === "initializing"
                        ? "First stop experiment"
                        : "Delete experiment"
                    }
                  >
                    <IconButton
                      onClick={() => {
                        clearAlerts();
                        experiment.state !== "running" &&
                          experiment.state !== "initializing" &&
                          setOpenDeleteCheck(true);
                      }}
                      disabled={
                        experiment.state === "running" ||
                        experiment.state === "initializing"
                      }
                    >
                      <DeleteIcon
                        title="delete experiment"
                        sx={{
                          color:
                            experiment.state === "running" ||
                            experiment.state === "initializing"
                              ? "rgba(255, 255, 255, 0.3)"
                              : "error.main",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            )}
            {/* Show more */}
            {!showMore ? (
              <Box display="flex" justifyContent="flex-end">
                <Tooltip title="Show more experiment details">
                  <Button
                    variant="text"
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      fontSize: "1rem",
                      textTransform: "none",
                      color: "text.icon",
                    }}
                    onClick={() => setShowMore((prev) => !prev)}
                  >
                    <ExpandMoreIcon />
                  </Button>
                </Tooltip>
              </Box>
            ) : (
              // Show less
              <Box display="flex" justifyContent="end">
                <Tooltip title="See less experiment details">
                  <Button
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      fontSize: "1rem",
                      textTransform: "none",
                      color: "text.icon",
                    }}
                    onClick={() => setShowMore((prev) => !prev)}
                  >
                    <ExpandLessIcon />
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>

      {/* Confirm delete */}
      <CustomDialog
        header={`Are you sure you want to delete ${experiment.experiment_title}?`}
        isOpen={openDeleteCheck}
        handleClose={() => {
          clearAlerts();
          setOpenDeleteCheck(false);
        }}
        confirmAction={() => {
          clearAlerts();
          callDeleteExperiment(
            accessToken,
            experiment.experiment_id,
            setLoading,
            setError,
            setMessage,
            setExperiments
          );
          setOpenDeleteCheck(false);
        }}
        cancelAction={() => {
          clearAlerts();
          setOpenDeleteCheck(false);
        }}
      />

      {/* Confirm stop */}
      <Dialog
        open={stopDialogOpen}
        onClose={handleStopDialogClose}
        aria-labelledby="seed-change-title"
      >
        <DialogTitle id="seed-change-title">
          {"Stop Experiment?"}
          <IconButton
            style={{ position: "absolute", right: 7, top: 7, padding: 3 }}
            onClick={handleStopDialogClose}
            size="small"
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Stop your current experiment and return to the lab?
          </Typography>
          <Typography>
            {/* (Your genome progress is autosaved. If you wish to save the current
            brain state, <span style={{ fontWeight: "600" }}>freeze</span> it
            from the navbar.) */}
            (Your genome progress is autosaved, but anything the brain has
            learned using it will be lost.)
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "8px 24px 16px 24px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            onClick={handleStopDialogClose}
            color="accents"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => handleStop(event)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExperimentCard;
